import { StyledStructured } from 'components/StyledStructured';
import { Features, TopSlogan, BottomSlogan } from './styles';
import Image from 'components/Image';
import { RoundedContainer } from '../../../../styles/elements';

const Hero = ({ title, description, features }) => {
  return (
    <RoundedContainer>
      <TopSlogan>
        <StyledStructured data={title} fw={400} fz={104} lh={88} />
      </TopSlogan>
      <Features>
        {features.map(({ label, photo }, index) => (
          <li key={index}>
            <Image image={photo} alt={label} />
            <span>{label}</span>
          </li>
        ))}
      </Features>
      <BottomSlogan>
        <StyledStructured data={description} fw={400} fz={37} lh={36} />
      </BottomSlogan>
    </RoundedContainer>
  );
};

export { Hero };

import { graphql } from 'gatsby';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer } from 'components/UI/Containers';
import { Hero } from './components/Hero';
import { Steps } from './components/Steps';
import { ServicesList } from '../../components/ServicesList';
import { RoundedContainer } from '../../styles/elements';
import { CallBackForm } from 'components/Layout/CallBackForm';

const HomePageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsSymmetryHomePage: pageData,
  } = data;
  const { seo, description, title, features, steps, stepsBackground, stepsHeading } = pageData;
  const { seoTitle, seoDescription, seoImage } = seo || {};

  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <Hero {...{ title, description, features }} />
        <Steps {...{ steps, stepsHeading, stepsBackground }} />
        <CallBackForm />
        <RoundedContainer>
          <ServicesList showPrice={false} />
        </RoundedContainer>
      </MainContainer>
    </PageWrapper>
  );
};

export default HomePageTemplate;

export const query = graphql`
  query HomePageQuery($locale: String!) {
    datoCmsSymmetryHomePage(locale: { eq: $locale }) {
      locale
      title {
        value
      }
      description {
        value
      }
      features {
        label
        photo {
          format
          gatsbyImageData(width: 420, height: 500)
          url
        }
      }
      stepsHeading {
        value
      }
      stepsBackground {
        gatsbyImageData
        url
        format
      }
      steps {
        id: originalId
        description
        title
        image {
          format
          gatsbyImageData(width: 280, height: 310)
          url
        }
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }
  }
`;

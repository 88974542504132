import Image from 'components/Image';
// eslint-disable-next-line import/named
import { Wrapper, StepsList, StepsBg, StepsHeading } from './styles';
import { StyledStructured } from '../../../../components/StyledStructured';

const Steps = ({ steps, stepsHeading, stepsBackground }) => {
  return (
    <Wrapper>
      <StepsHeading>
        <StyledStructured data={stepsHeading} fw={600} fz={54} lh={54} />
      </StepsHeading>
      <StepsBg image={stepsBackground} />
      <StepsList>
        {steps?.map(({ title, description, image, id }, index) => (
          <li key={id}>
            <h4>{title}</h4>
            <p>{description}</p>
            <Image image={image} alt={title} />
            <span>{`0${index + 1}`}</span>
          </li>
        ))}
      </StepsList>
    </Wrapper>
  );
};

export { Steps };

import { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import IconStar from 'assets/icons/icon-star.svg';
import { usePageLocale } from '../../../hooks/usePageLocale';
import {
  Wrapper,
  Form,
  LeftSide,
  Additional,
  FormGroup,
  RightSide,
  Title,
  Information,
  Phone,
  MapCard,
  MapCardContent,
  MapCardRating,
  MapCardAddress
} from './styles';
import Image from '../../Image';
import { Socials } from '../Footer/styles';
import { CTA } from '../Header/styles';

const CallBackForm = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsSymmetryCallbackform {
        formNodes: nodes {
          id: originalId
          locale
          title
          information
          additionalText
          clinicName
          rating
          reviews
          address
          image {
            gatsbyImageData
            url
            format
          }
          inputLabel
          inputPlaceholder
          ctaText
          socials {
            link
            logo {
              gatsbyImageData
              format
              url
            }
          }
        }
      }
    }
  `);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const myForm = event.target;
    const formData = new FormData(myForm);

    const phone = formData.get('phone');
    const phoneRegex = /^(?:\+380|380|0)?[5-9]\d{8}$/;

    // Перевірка формату номера телефону
    if (!phoneRegex.test(phone)) {
      setError('Будь ласка, введіть правильний український номер телефону');
      setStatus('error');
      return;
    }

    try {
      setStatus('pending');
      setError('');

      const res = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      });

      if (res.status === 200) {
        setStatus('ok');
      } else {
        setStatus('error');
        setError(`${res.status} ${res.statusText}`);
      }
    } catch (e) {
      setStatus('error');
      setError(`${e}`);
    }
  };

  const {
    allDatoCmsSymmetryCallbackform: { formNodes },
  } = data;
  const { pageLocale } = usePageLocale();

  return (
    <>
      {formNodes
        .filter(({ locale }) => locale === pageLocale)
        .map((node) => {
          const {
            title,
            information,
            additionalText,
            id,
            inputLabel,
            inputPlaceholder,
            ctaText,
            socials,
            image,
            address,
            clinicName,
            rating,
            reviews
          } = node;

          return <Wrapper key={id}>
            <LeftSide>
              <Title>{title}</Title>
              <Form
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleFormSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="bot-field" />
                <FormGroup>
                  <label htmlFor="phone">{inputLabel}</label>
                  <input
                    type="tel"
                    name="phone"
                    placeholder={inputPlaceholder}
                    pattern="\+?[0-9]{10,13}"
                    inputMode="tel"
                  />
                </FormGroup>
                <CTA className="submit" as="button" type="submit" disabled={status === 'pending'}>
                  <span>{status === 'pending' ? 'Sending...' : ctaText}</span>
                </CTA>

                {status === 'ok' && <div>Submitted!</div>}
                {status === 'error' && <div>{error}</div>}
              </Form>
              <Information>{information}</Information>
            </LeftSide>
            <RightSide>
              <div>
                <Additional>{additionalText}</Additional>
                <Phone href="tel:+380731232580">+38 073 123 25 80</Phone>
                <Socials>
                  {socials.map((social, index) => (
                    <a
                      key={index}
                      href={social.link}
                      aria-label={`Social media link ${index + 1}`}
                    >
                      <Image image={social.logo} />
                    </a>
                  ))}
                </Socials>
              </div>
              <MapCard href="#">
                <Image image={image} />
                <MapCardContent>
                  <div>{clinicName}</div>
                  <MapCardRating>
                    <span>{Array(5).fill().map((_, index) => <IconStar key={index} />)}</span>
                    <span>{`${rating} (${reviews})`}</span>
                  </MapCardRating>
                  <MapCardAddress>{address}</MapCardAddress>
                </MapCardContent>
              </MapCard>
            </RightSide>
          </Wrapper>;
        })}
    </>
  );
};

export { CallBackForm };

import styled from 'styled-components';
import { media } from '../../../styles/mixins';

export const Wrapper = styled.div`
    padding: 45px 36px;
    background-color: #fff;
    border-radius: 30px;
    margin-bottom: 90px;
    display: flex;
    gap: 20px;

    ${media.tablet`
          flex-direction: column;
        `}
`;

export const Form = styled.form`
    background-color: #FAF7F9;
    display: flex;
    align-items: center;
    margin-top: 60px;
    width: 100%;
    max-width: 600px;
    border-radius: 40px;
`;

export const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RightSide = styled.div`
    width: 480px;
`;

export const Title = styled.div`
    color: #030D41;
    font-size: 54px;
    font-weight: 600;
    line-height: 54px;
    
    ${media.tablet`
          font-size: 40px;
        `}
    
    ${media.mobile`
          font-size: 27px;
          line-height: 36px;
        `}
`;

export const Information = styled.div`
    color: #6E707C;
    font-size: 27px;
    font-weight: 600;
    line-height: 36px;
    margin-top: auto;
`;

export const Additional = styled.div`
    color: #6E707C;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
`;

export const Phone = styled.a`
    display: flex;
    color: #6E707C;
    font-size: 27px;
    font-weight: 600;
    line-height: 36px;
    margin-top: 18px;
    margin-bottom: 27px;
`;

export const FormGroup = styled.div`
    position: relative;
    width: 100%;
    
    & + button {
        padding: 11px;
        
        span {
            font-weight: 600;
        }
    }
    
    label {
        color: #6E707C;
        position: absolute;
        top: -24px;
        font-size: 15px;
        font-weight: 400;
        line-height: 15px;
    }
    
    input {
        margin: 0;
        border: none;
        outline: none;
        background: none;
        font-size: 18px;
        color: #6E707C;
        box-shadow: none;
        appearance: none;
        padding: 18px 27px;
        width: 100%;
        -webkit-appearance: none;
    }
`;

export const MapCard = styled.a`
    display: flex;
    flex-direction: column;
    padding: 18px;
    margin-top: 18px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    
    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(26, 13, 41, 0.3);
    }

    .gatsby-image-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 1;
    }
`;

export const MapCardContent = styled.div`
    position: relative;
    z-index: 3;
    color: #FFF;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: 9px;
`;

export const MapCardRating = styled.div`
    display: flex;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    align-items: center;
    gap: 9px;
    
    span:nth-child(1) {
        display: flex;
    }
`;

export const MapCardAddress = styled.div`
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
`;

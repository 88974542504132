import styled from 'styled-components';
import Image from 'components/Image';
import { media } from 'styles/mixins';

export const Wrapper = styled.div`
  position: relative;
  margin-top: 180px;
  margin-bottom: 90px;
`;

export const StepsHeading = styled.div`
    position: relative;
    width: 800px;
    z-index: 2;
    max-width: 100%;
    
    p {
        ${media.mobile`
          font-size: 34px;
          line-height: 40px;
        `}
    }
`;

export const StepsList = styled.ul`
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  margin: 36px 0;
  gap: 17px;
    z-index: 2;
    
    ${media.tablet`
          grid-template-columns: repeat(2, 1fr);
        `}
    
    ${media.mobile`
          grid-template-columns: repeat(1, 1fr);
        `}
  

  li {
        display: flex;
        flex-direction: column;
    position: relative;
    flex-shrink: 0;
    border-radius: 20px;
    background-color: #fff;
    padding: 27px;

    h4 {
      margin-top: 0;
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
        min-height: 2em;
    }
    
    p {
      margin-bottom: 27px;
      color: #6E707C;
        flex-grow: 1;
    }

    .gatsby-image-wrapper {
      margin-bottom: 18px;
        border-radius: 10px;
    }
    
    span {
      color: #A19EA5;
        margin-top: auto;
    }
  }
`;

export const StepsBg = styled(Image)`
  position: absolute;
  right: 74px;
  top: 0;
    z-index: 1;
    
    ${media.tablet`
          width: 400px;
          right: -12px;
          top: 76px;
        `}
    
    ${media.mobile`
          width: 234px;
          right: -90px;
          top: 62px;
        `}
`;

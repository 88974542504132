import styled from 'styled-components';
import { media } from 'styles/mixins';

export const TopSlogan = styled.div`
    h1 {
        ${media.tablet`
          font-size: 60px;
          line-height: 60px;
        `}

        ${media.mobile`
          font-size: 34px;
          line-height: 34px;
        `}
    }
`;

export const BottomSlogan = styled.div`
    p {
        ${media.tablet`
          font-size: 18px;
          line-height: 27px;
        `}
    }
`;

export const Features = styled.ul`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 36px 0;

    ${media.mobile`
      grid-template-columns: repeat(1, 1fr);
      min-height: 177px;
    `}

    li {
        display: block;
        position: relative;
        height: 100%;
        border-radius: 20px;
        background-color: #cd9ecd;

        .gatsby-image-wrapper {
            border-radius: 10px;
            width: 100%;
        }

        span {
            position: absolute;
            left: 24px;
            bottom: 24px;
            display: inline-block;
            padding: 12px 18px;
            justify-content: center;
            align-items: center;
            background: rgba(255, 255, 255, 0.35);
            backdrop-filter: blur(7.5px);
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            border-radius: 40px;

            ${media.tablet`
                  font-size: 15px;
                  line-height: 22px;
                `}
        }
    }
`;
